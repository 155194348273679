import { Checkbox } from "antd";
import styled, { css } from "styled-components";
import color from "../_style/color";

const checkboxTheme = {
  basic: css`
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: unset;
      border-color: ${color.gainsboro};
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: ${color.black};
      border-width: 1px;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${color.gainsboro};
    }

    :hover .ant-checkbox-inner {
      border-color: ${color.gainsboro};
    }
  `,
  primary: css`
    .ant-checkbox-checked .ant-checkbox-inner {
      background: ${color.primary};
      border-color: ${color.primary};
    }

    :hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${color.primary};
    }
  `,
};

export default styled(Checkbox).attrs((props) => ({
  height: props.height,
  width: props.width,
  checkboxType: props.checkboxType,
  centered: props.centered,
}))`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  .ant-checkbox-checked::after {
    content: unset;
  }

  ${({ checkboxType }) => checkboxTheme[checkboxType]}
  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;
