/** @typedef {import('axios').AxiosResponse} AxiosResponse */

import requestExodusConfig from 'config/requestExodus';

const requestExodus = requestExodusConfig();

// Configs
const VERIFIED_BADGE_URL = '/verified-badge';
const VERIFIED_BADGE_USER_URL = `${VERIFIED_BADGE_URL}/user`;
/**
 * Get verified badge user
 * @param {Number} userId - userid
 * @return {AxiosResponse}
 */
const getVerifiedBadgeUser = (userId) =>
  requestExodus.get(`${VERIFIED_BADGE_USER_URL}/${userId}`);

enum VerifiedStatus {
  VERIFIED_STATUS_UNVERIFIED = 'VERIFIED_STATUS_UNVERIFIED',
  VERIFIED_STATUS_IDENTITY = 'VERIFIED_STATUS_IDENTITY',
  VERIFIED_STATUS_COMMUNITY = 'VERIFIED_STATUS_COMMUNITY',
}

// Exports ---------------------------------------------------------------------
export { VerifiedStatus };
export default { getVerifiedBadgeUser };
