/* eslint-disable import/named */
/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */
import exodusInstance from 'lib/api/instances/exodus';
import { API_EXODUS_URL } from 'constants/api';
import {
  convertParams,
  notificationTypeUnreadParams,
} from 'utils/notification';
import { NotificationPayload } from 'lib/entities/notification/slice.types';
import { APIResponse } from '../../../@types/api-response';

const request = exodusInstance.noAlert;

const NOTIFICATION_URL = `${API_EXODUS_URL}/notification`;
const NOTIFICATION_READ_V2 = `${NOTIFICATION_URL}s/read`;
const NOTIFICATION_SETTINGS = `${NOTIFICATION_URL}/settings`;
const NOTIFICATION_UNREAD_COUNT_V2 = `${NOTIFICATION_URL}/count/unread`;

/**
 * Get notification
 * @param {Array|null=} types - notification types
 * @param {number|string=} limit - limit
 * @param {number|string=} last_id - last id
 * @returns {APIResponse}
 */
export const getNotification = (
  types = null,
  limit = 25,
  last_id = null,
): Promise<APIResponse<NotificationPayload>> => {
  // Using URLSearchParams to prevent types params converted to ?types[]=any&types[]=any
  // Because it shoulbe ?types=any&types=any
  const params = convertParams({
    types,
    limit,
    last_id,
  });
  return request.get(`${NOTIFICATION_URL}`, {
    params,
  });
};

/**
 * Read unread notification
 * @param {string} type - notification types
 * @returns {APIResponse}
 */
export const markAsRead = (types = []): Promise<APIResponse<boolean>> =>
  request.patch(NOTIFICATION_READ_V2, { types });

/**
 * Get notification settings
 * @param {string} type - notification types
 * @param {string} categories - setting categories
 * @returns {APIResponse}
 */
export const getNotificationSetting = (
  type,
  categories,
): Promise<APIResponse> =>
  request.get(NOTIFICATION_SETTINGS, {
    params: {
      type,
      categories,
    },
  });

/**
 * Set up notification settings per category
 * @param {string} type - notification types
 * @param {string} category - setting's category
 * @param {boolean} value - setting's category value (true | false)
 * @returns {APIResponse}
 */
export const setNotificationSettings = (
  type = '',
  category = '',
  value = false,
): Promise<APIResponse> =>
  request.put(`${NOTIFICATION_SETTINGS}/update`, {
    type,
    setting: {
      [category]: value,
    },
  });

/**
 * Get unread notification count
 * @returns {APIResponse}
 */
export const getUnreadNotificationNumber = (): Promise<
  APIResponse<{ unread: number }>
> => {
  const types = notificationTypeUnreadParams;
  const params = convertParams({
    types,
  });
  return request.get(NOTIFICATION_UNREAD_COUNT_V2, {
    params,
  });
};
