/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import auth from './auth';
import { decodeBase64ToJS, emptyObject, saveObject } from './general';

// WS CONFIG VARIABLES
const WS_LIVEPRICE = true;
const WS_STREAM = true;
const WS_TRADING = true;

/**
 * Parse new socket string data, identified with # property
 * @param  {String} socketString - String you want to parse, returned from socket
 * @return {Object} Parsed socket string in object form
 */
function parseSocketString(socketString = '') {
  const parsedRawObject = socketString.split('|');

  let parsedObject = {};
  const priceObjectList = [];
  const hotlistData = [];

  switch (parsedRawObject[0]) {
    case '#C':
      parsedObject = {
        C: {
          sym: parsedRawObject[1],
          date_unix: Number(parsedRawObject[2]),
          ohlc: {
            Adj_Close: Number(parsedRawObject[4]),
            Close: Number(parsedRawObject[8]),
            High: Number(parsedRawObject[6]),
            Low: Number(parsedRawObject[7]),
            Open: Number(parsedRawObject[5]),
            volume: Number(parsedRawObject[9]),
            date_unix: Number(parsedRawObject[2]),
            date_formated: parsedRawObject[3],
            dividend: Number(parsedRawObject[13]),
            foreign_buy: Number(parsedRawObject[14]),
            foreign_flow: Number(parsedRawObject[15]),
            foreign_sell: Number(parsedRawObject[16]),
            frequency: Number(parsedRawObject[17]),
            marketcap: Number(parsedRawObject[18]),
            value: Number(parsedRawObject[19]),
            shareoutstanding: Number(parsedRawObject[20]),
            ann_eps: Number(parsedRawObject[21]),
            ann_pe: Number(parsedRawObject[22]),
            avg_price: Number(parsedRawObject[23]),
            previous: Number(parsedRawObject[24]),
            freq_analyzer: Number(parsedRawObject[25]),
          },
          widget: {
            [parsedRawObject[1]]: {
              lastprice: Number(parsedRawObject[10]),
              price_change: Number(parsedRawObject[11]),
              percentage_change: Number(parsedRawObject[12]),
            },
          },
          'OFFER-RG': {},
          'BID-RG': {},
          __t: parsedRawObject[26],
        },
        __t: parsedRawObject[26],
      };
      break;
    case '#O':
      // eslint-disable-next-line no-case-declarations
      const arrayLength = parsedRawObject.length - 2;

      for (let i = 3; i <= arrayLength; i++) {
        // eslint-disable-line no-plusplus
        if (
          parsedRawObject[i] &&
          typeof parsedRawObject[i] === 'string' &&
          parsedRawObject[i].includes(';')
        ) {
          const splittedPrice = parsedRawObject[i].split(';');
          const [price, queue, volume] = splittedPrice;
          const priceObject = { price, queue, volume };
          priceObjectList.push(priceObject);
        }
      }

      parsedObject = {
        O: {
          sym: parsedRawObject[1],
        },
        __t: parsedRawObject[13],
      };

      if (parsedRawObject[2] === 'OFFER') {
        parsedObject.O['OFFER-RG'] = {};
        priceObjectList.forEach((priceObject, index) => {
          parsedObject.O['OFFER-RG'][`price${index + 1}`] = Number(
            priceObject.price,
          );
          parsedObject.O['OFFER-RG'][`que_num${index + 1}`] = Number(
            priceObject.queue,
          );
          parsedObject.O['OFFER-RG'][`volume${index + 1}`] = Number(
            priceObject.volume,
          );
        });
      }

      if (parsedRawObject[2] === 'BID') {
        parsedObject.O['BID-RG'] = {};
        priceObjectList.forEach((priceObject, index) => {
          parsedObject.O['BID-RG'][`price${index + 1}`] = Number(
            priceObject.price,
          );
          parsedObject.O['BID-RG'][`que_num${index + 1}`] = Number(
            priceObject.queue,
          );
          parsedObject.O['BID-RG'][`volume${index + 1}`] = Number(
            priceObject.volume,
          );
        });
      }

      break;
    case '#H':
      for (let i = 2; i < parsedRawObject.length; i++) {
        // eslint-disable-line no-plusplus
        const companyData = parsedRawObject[i].split(';');
        if (companyData[0]) {
          hotlistData[i - 2] = {
            symbol: companyData[0],
            name: companyData[1],
            change: Number(companyData[2]),
            lastprice: Number(companyData[3]),
            percentage_change: Number(companyData[4]),
            // for notation and corporate action
            company_id: Number(companyData[5]),
            withNotation: Number(companyData[6]) === 1,
            withUMA: Number(companyData[7]) === 1,
            withCorpAction: Number(companyData[8]) === 1,
          };
        }
      }

      parsedObject = {
        H: {
          [parsedRawObject[1]]: hotlistData,
        },
      };
      break;
    case '#I':
      parsedObject = {
        I: {
          sym: parsedRawObject[1],
          date_unix: Number(parsedRawObject[2]),
          ohlc: {
            close: Number(parsedRawObject[8]),
            high: Number(parsedRawObject[6]),
            low: Number(parsedRawObject[7]),
            open: Number(parsedRawObject[5]),
            volume: Number(parsedRawObject[9]),
          },
        },
      };
      break;
    default:
      parsedObject = {
        info: parsedRawObject,
      };
      break;
  }

  return parsedObject;
}

const setLocalWskey = (access_wskey) => saveObject('ws', access_wskey);

const getLocalWskey = () => {
  const ls = window.localStorage;
  try {
    return ls.ws ? decodeBase64ToJS(ls.ws) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const useLocalWskey = () => {
  const ls = window.localStorage;
  let toSend = '';
  const wskey = ls.ws ? decodeBase64ToJS(ls.ws) : null;
  if (wskey) {
    toSend = wskey.pop();
    setLocalWskey(wskey); // update wskey local
  }
  return toSend;
};

// websocket config methods
/**
 * Get primus config
 * @param {*} c - subscribe company
 * @param {*} s - subscribe stream -> main stream , symbol stream, profile stream
 * @param {*} o - subscribe orderbook
 * @param {*} h - subscribe hotlist
 * @param {*} t - subscribe trading
 * @returns {Object}
 */
function getConfig(c, s, o, h = false, t) {
  const user = auth.getUserAccess();
  const config = {
    loggedInUser: user?.id,
    chname: {
      C: [],
      S: [],
      O: [], // to subscribe company orderbook
      // "C": c || [], // to subscribe company
      // "C": [], // 1 , orderbook , chartbit , company profile, sector
      // "S": ["*.news"], // main stream , symbol stream, profile stream
      // "S": [], // main stream , symbol stream, profile stream
      // "S": s || ["*"], // main stream , symbol stream, profile stream
      // "H": false, // hotlist
      T: [], // untuk trading
      H2: h,
    },
  };

  if (WS_LIVEPRICE) config.chname.C = c || [];
  if (WS_STREAM) {
    config.chname.S = s || [];
  }
  if (WS_TRADING) config.chname.T = t || [];
  config.chname.O = o || [];
  return config;
}

/**
 * Write/send/emit subscription data to backend websocket
 * @param {Object} primus - Primus websocket
 * @param {Object} config - WS config
 */
function extendWrite(primus, config) {
  if (!primus) return;

  if (!WS_STREAM) config.chname.S = [];
  if (
    config.chname.S.length > 0 &&
    config.chname.S[0].toLowerCase().includes('all')
  ) {
    const [category] = config.chname.S[0].split('.');
    const newConfigChnameS = [...config.chname.S];
    newConfigChnameS[0] = category;
    config.chname.S = newConfigChnameS;
  }

  primus.write(config);
}

/**
 * Initiate websocket subscription
 * @param {Object} primus - Primus websocket
 * @param {Object} state - Websocket reducer
 * @param {string} wskey
 */
const initConfig = (primus, state, wskey) => {
  const ch = {};
  if (!ch.S) ch.S = state.livedata_channels.stream;
  if (!ch.C) ch.C = state.livedata_channels.company;
  if (!ch.O) ch.O = state.livedata_channels.orderbook;

  const socket_config = getConfig(ch.C, ch.S, ch.O);
  if (wskey) socket_config.wskey = wskey;
  socket_config.chname.O = socket_config.chname.O.filter(Boolean);
  socket_config.chname.C = socket_config.chname.C.filter(Boolean);

  extendWrite(primus, socket_config);
};

/**
 * Update websocket subscription config
 * @param {object} primus
 * @param {object} state - Websocket reducer state
 * @param {string} wskey
 */
const updateConfig = (primus, state, wskey) => {
  const ch = {};
  if (!ch.S) ch.S = state.stream;
  if (!ch.C) ch.C = state.company || [];
  if (!ch.O) ch.O = state.orderbook || [];
  if (!ch.H2) ch.H2 = state.hotlist;

  const socket_config = getConfig(ch.C, ch.S, ch.O, ch.H2);
  if (wskey) socket_config.wskey = wskey;
  socket_config.chname.O = socket_config.chname.O.filter(Boolean);
  socket_config.chname.C = socket_config.chname.C.filter(Boolean);
  extendWrite(primus, socket_config);
};

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

/**
 * To re evaluate config
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
const updateGeneralWsConfig = (state, newConfig) => {
  const config = composeGeneralWsConfig(newConfig); // generate new config
  const result = needRerender(state, config); // need rerender ?
  if (!result) return false; // difference not found
  return config;
};

const needRerender = (state, next) => {
  let { livedata_channels } = state || {};
  if (!livedata_channels) livedata_channels = {};
  const toCompare = {
    stream: livedata_channels.stream || [],
    company: livedata_channels.company || [],
    orderbook: livedata_channels.orderbook || [],
    hotlist: livedata_channels.hotlist || false,
  };

  const orderbook_diff = !arraysEqual(toCompare.orderbook, next.orderbook); // company difference
  const company_diff = !arraysEqual(toCompare.company, next.company); // company difference
  const stream_diff = !arraysEqual(toCompare.stream, next.stream); // stream difference
  const hotlist_diff = toCompare.hotlist !== next.hotlist;
  if (stream_diff || company_diff || orderbook_diff || hotlist_diff) return true;
  return false; // no need to re render
};

const composeGeneralWsConfig = (state) => {
  const config = {};

  /**
   * config for company
   */

  config.company = [];

  const merged = [...(state?.company || []), ...(state?.orderbook || [])];

  const merged_orderbook = [...(state?.orderbook || [])];

  const unique = [...new Set(merged)]; // uniq array
  const unique_orderbook = [...new Set(merged_orderbook)]; // uniq array orderbook
  const new_map = unique
    .filter((v) => v !== undefined)
    .map((v) => (typeof v === 'string' ? v.toUpperCase() : v));
  const new_orderbook = unique_orderbook
    .filter((v) => v !== undefined)
    .map((v) => (typeof v === 'string' ? v.toUpperCase() : v));
  // console.log('::-- new_map ', new_map) // unique
  // console.log('::-- new_orderbook', new_orderbook) // unique
  config.company = new_map;
  config.orderbook = new_orderbook;

  // console.log('::@@ Socket O SUBSCRIBE', new_orderbook)
  // console.log('::@@ Socket C SUBSCRIBE', new_map)
  /**
   * end config for company
   */

  /**
   * config for stream
   */

  const stream = [...(state?.stream || [])];

  config.stream = [...new Set(stream)];

  /**
   * end config for stream
   */

  // Socket config for Hotlist, only available if widget has data
  config.hotlist = state?.hotlist;

  return config;
};

const convertWSKeyArray = (wskey) => {
  if (wskey && typeof wskey === 'string') {
    return [wskey];
  }
  return wskey;
};

export default {
  parseSocketString,
  setLocalWskey,
  getLocalWskey,
  useLocalWskey,
  getConfig,
  extendWrite,
  initConfig,
  updateConfig,
  updateGeneralWsConfig,
  convertWSKeyArray,
};
