import styled from "styled-components";

export default styled.div.attrs((props) => ({
  icon: props.icon || "",
  iconSize: props.iconSize || "12px",
  padding: props.padding || "unset",
  width: props.width || "12px",
  height: props.height || "12px",
  margin: props.margin || "unset",
}))`
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  width: ${({ iconSize }) => iconSize};
  height: ${({ iconSize }) => iconSize};
  background: url(${({ icon }) => icon}) center center/ ${({ iconSize }) => iconSize} auto no-repeat;
`;
