import { CommenterState, PollingParams, TargetPriceParams } from './types';

const ALLOWED_DOC_EXT: Array<string> = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpointtd',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

// max file size: 0MB
const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE: number = MAX_FILE_SIZE_MB * 1024 * 1024;

const MAX_IMAGE_SIZE_MB = 2;
const MAX_IMAGE_SIZE: number = MAX_IMAGE_SIZE_MB * 1024 * 1024;

const COMPOSE_MODE = {
  COMPOSE: 'compose',
  EDIT: 'edit',
  REPOST: 'repost',
  SHARE: 'share',
  SHARE_TEXT: 'share-link',
};

const initialPolling: PollingParams = {
  isOpen: false,
  duration: {
    Days: 1,
    Hours: 0,
    Mins: 0,
  },
  polling_pin: 0,
  polling_options: [
    { id: 1, value: '' },
    { id: 2, value: '' },
  ],
  polling_end: 0,
};

const initialTargetPrice: TargetPriceParams = {
  isOpen: false,
  target_duration: '7',
  target_price: 0,
  target_symbol: '',
};

const IMAGE_TYPE_GIF = 'GIF';
const IMAGE_TYPE_COMMON = 'COMMON';

const COMMENT_SETTING_LIST: Array <CommenterState> = [
  {
    id: 1,
    label: 'Everyone',
    type: 'COMMENTER_TYPE_EVERYONE',
    icon: 'comment-globe.svg',
  },
  {
    id: 2,
    label: 'Only people you follow',
    type: 'COMMENTER_TYPE_FOLLOWING',
    icon: 'comment-people.svg',
  },
  {
    id: 3,
    label: 'Only people you mention',
    type: 'COMMENTER_TYPE_MENTION',
    icon: 'comment-mention.svg',
  },
  {
    id: 4,
    label: 'Only you',
    type: 'COMMENTER_TYPE_ONLY_YOU',
    icon: 'comment-private.svg',
  },

];

const ADMIN_USER_PRIVILEGE = ['1', '2', '4'];

export {
  ALLOWED_DOC_EXT,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
  COMPOSE_MODE,
  initialPolling,
  initialTargetPrice,
  IMAGE_TYPE_GIF,
  IMAGE_TYPE_COMMON,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_MB,
  COMMENT_SETTING_LIST,
  ADMIN_USER_PRIVILEGE,
};
