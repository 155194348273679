import styled, { css } from "styled-components";
import color from "../_style/color";
import handleSpaces, { spacingProps } from "../utils/handleSpaces";

const avatarSize = {
  "x-small": "30px",
  small: "40px",
  medium: "50px",
  large: "70px",
  "x-large": "100px",
  "xx-large": "120px",
};

export default styled.div.attrs((props) => ({
  size: props.size || "100%",
  padding: props.padding || 0,
  margin: props.margin || 0,
  ...spacingProps(props),
}))`
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  ${(props) =>
    css`
      ${handleSpaces(props)}
    `}
  & > img {
    background: ${color.lowgray};
    width: ${(props) => avatarSize[props.size]};
    height: ${(props) => avatarSize[props.size]};
    border-radius: 50%;
    border: 1px solid ${color.component};
  }
`;
