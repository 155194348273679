import Image from 'global/Image';
import { handleErrorAvatar, transformAvatarUrl } from 'utils/general';
import { ChatRoomType } from 'features/chat/types';
import { AvatarContainer, GroupAvatar } from './styled';
import { DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_ITEM_AVATAR } from './__tests__/constant';

export interface RoomItemAvatarProps {
  type: ChatRoomType;
  src?: string;
  name: string;
  shortName: string;
  isDeactive: boolean;
}

function RoomItemAvatar({
  type,
  src,
  name,
  shortName,
  isDeactive,
}: Readonly<RoomItemAvatarProps>) {
  if (type === 'ROOM_TYPE_PERSONAL') {
    return (
      <AvatarContainer
        data-cy={DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_ITEM_AVATAR}
      >
        {isDeactive ? (
          <Image src="/images/avatar.png" alt="User Not Found Avatar" />
        ) : (
          <Image
            alt={name}
            src={transformAvatarUrl(src)}
            onError={(e) => handleErrorAvatar(e, name)}
          />
        )}
      </AvatarContainer>
    );
  }

  return (
    <AvatarContainer
      data-cy={DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_ITEM_AVATAR}
    >
      {src === '' ? (
        <GroupAvatar>
          <i data-username={name}>{shortName}</i>
        </GroupAvatar>
      ) : (
        <Image
          alt={name}
          src={src}
          onError={(e) => handleErrorAvatar(e, name)}
        />
      )}
    </AvatarContainer>
  );
}

export default RoomItemAvatar;
