/* eslint-disable import/named */
/* eslint-disable max-len */
import { APIPromiseResponse } from '../../../@types/api-response';
import {
  PostPriceAlertData,
  PriceAlertData,
} from '../../../@types/price-alert';
import requestConfig from 'config/requestExodus';

import { API_EXODUS_URL } from 'constants/api';

const alertURL = `${API_EXODUS_URL}/alert`;

const request = requestConfig();

/**
 * side widget: price alert
 * @param {Object} opt   - Get active alert options
 * @param {Object} page  - Paging number for getting active alert
 * @param {Object} limit - Amount grabbed for each getting active alert activity
 */
interface getDataPriceAlertParams {
  page: number;
  limit: number;
  symbol?: string;
}
const getDataPriceAlert = (
  params: getDataPriceAlertParams,
): APIPromiseResponse<Array<PriceAlertData>> =>
  request.get(`${alertURL}/status/active`, {
    params,
  });

/**
 * side widget: alert log
 * @param {Number} statusNumber - statusNumber isinya 2 atau 4 dari web legacy, cek aja sendiri
 * @param {Object} opt   - Get active alert options
 * @param {Object} page  - Paging number for getting active alert
 * @param {Object} limit - Amount grabbed for each getting active alert activity
 */
interface getAlertLogParams {
  page: number;
  limit: number;
  symbol?: string;
}
const getDataAlertLog = (
  statusNumber: string | number = '',
  opt: getAlertLogParams,
): APIPromiseResponse<Array<PriceAlertData>> => {
  const status = statusNumber;

  return request.get(`${alertURL}`, {
    params: {
      ...opt,
      status,
    },
  });
};

const getDetailAlert = (alertId: string): APIPromiseResponse<PriceAlertData> =>
  request.get(`${alertURL}/${alertId}`);

// command is a string of {"stock": [stock], "value": [value], "operator": [opt], "item": "[item]}
const addPriceAlert = (
  name: string,
  command: string,
): APIPromiseResponse<PostPriceAlertData> => {
  const options = {
    name,
    command,
  };

  return request.post(`${alertURL}`, options);
};

const editPriceAlert = (
  alertId: string,
  name: string,
  command: string,
): APIPromiseResponse<PostPriceAlertData> => {
  const options = { name, command };

  return request.put(`${alertURL}/${alertId}`, options);
};

const cancelPriceAlert = (id: string): APIPromiseResponse<Array<string>> =>
  request.delete(`${alertURL}/${id}`);

export default {
  getDataPriceAlert,
  getDataAlertLog,
  getDetailAlert,
  addPriceAlert,
  editPriceAlert,
  cancelPriceAlert,
};
