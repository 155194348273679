import reactStringReplace from 'react-string-replace';

/**
 * Notification types mapping
 */
export const notificationTypesMap = {
  company: ['NOTIF_TYPE_NEW_REPORT'],
  stream: ['NOTIF_TYPE_MENTIONS', 'NOTIF_TYPE_REPLY'],
  other: [
    'NOTIF_TYPE_FOLLOWING',
    'NOTIF_TYPE_RETWEET',
    'NOTIF_TYPE_LIKE',
    'NOTIF_TYPE_STREAMS_UPDATE',
    'NOTIF_TYPE_POSTALERT',
    'NOTIF_TYPE_STOCK_ALERT',
    'NOTIF_TYPE_TARGETPRICE',
    'NOTIF_TYPE_TRADING_MATCH_ORDER',
    'NOTIF_TYPE_TRADING_COMMUNITY',
    'NOTIF_TYPE_STREAMS_UPDATE',
    'NOTIF_TYPE_NEW_TRENDING',
    'NOTIF_TYPE_CALENDAR',
    'NOTIF_TYPE_NEWSFEED',
    'NOTIF_TYPE_GROUPCHAT_INVITE',
    'NOTIF_TYPE_VERIFIED_BADGE',
    'NOTIF_TYPE_SAVE',
    'NOTIF_TYPE_SECURITIES_DAY_TRADE',
    'NOTIF_TYPE_SHARIA_STOCK_DELISTED',
  ],
  tipping: [
    'NOTIF_TYPE_TIPPING_RECEIVER',
    'NOTIF_TYPE_TIPPING_SENDER',
    'NOTIF_TYPE_CLAIM',
  ],
};

export const notificationTypeUnreadParams = [
  'NOTIF_TYPE_INBOX',
  'NOTIF_TYPE_MENTIONS',
  'NOTIF_TYPE_FOLLOWING',
  'NOTIF_TYPE_RETWEET',
  'NOTIF_TYPE_REPLY',
  'NOTIF_TYPE_LIKE',
  'NOTIF_TYPE_SAVE',
  'NOTIF_TYPE_POSTALERT',
  'NOTIF_TYPE_TRADING_MATCH_ORDER',
  'NOTIF_TYPE_NEW_TRENDING',
  'NOTIF_TYPE_STOCK_ALERT',
  'NOTIF_TYPE_NEWUSERJOIN',
  'NOTIF_TYPE_TARGETPRICE',
  'NOTIF_TYPE_TIPPING_RECEIVER',
  'NOTIF_TYPE_TIPPING_SENDER',
  'NOTIF_TYPE_CLAIM',
  'NOTIF_TYPE_VERIFIED_BADGE',
  'NOTIF_TYPE_SECURITIES_DAY_TRADE',
  'NOTIF_TYPE_SHARIA_STOCK_DELISTED',
  'NOTIF_TYPE_SECURITIES_DEPOSIT',
  'NOTIF_TYPE_SECURITIES_WITHDRAW',
  'NOTIF_TYPE_SECURITIES_REFERRAL',
  'NOTIF_TYPE_SECURITIES_MATCH',
  'NOTIF_TYPE_SECURITIES_REJECT',
  'NOTIF_TYPE_MESSAGE_REQUEST',
  'NOTIF_TYPE_EIPO_CONFIRMATION',
  'NOTIF_TYPE_GROUPCHAT',
  'NOTIF_TYPE_NEW_REPORT',
  'NOTIF_TYPE_NEWSFEED',
  'NOTIF_TYPE_COMPANY_PUBLIC_EXPOSE',
  'NOTIF_TYPE_COMPANY_SHAREHOLDING',
  'NOTIF_TYPE_COMPANY_DIVIDEND',
  'NOTIF_TYPE_COMPANY_CORP_ACTION',
  'NOTIF_TYPE_COMPANY_OTHERS',
  'NOTIF_TYPE_TRADING_COMMUNITY',
];

const notificationReportType = [
  'NOTIF_TYPE_NEW_REPORT',
  'NOTIF_TYPE_NEWSFEED',
  'NOTIF_TYPE_COMPANY_PUBLIC_EXPOSE',
  'NOTIF_TYPE_COMPANY_SHAREHOLDING',
  'NOTIF_TYPE_COMPANY_DIVIDEND',
  'NOTIF_TYPE_COMPANY_CORP_ACTION',
  'NOTIF_TYPE_COMPANY_OTHERS',
];
/**
 * Tabs attributes (className, name, typeCodes)
 */
export const notificationTabsAttrs = [
  {
    name: 'notification',
    className: 'icon-notification_activity',
    // typeCodes: '3,5,6,7,9,12,16',
    typeCodes: notificationTypeUnreadParams,
  },
  {
    name: 'mention',
    className: 'icon-notification_mention',
    typeCodes: ['NOTIF_TYPE_MENTIONS'],
  },
  {
    name: 'report',
    className: 'icon-notification_report',
    typeCodes: notificationReportType,
  },
  {
    name: 'tipping',
    className: 'icon-notification_tip',
    typeCodes: [
      'NOTIF_TYPE_TIPPING_RECEIVER',
      'NOTIF_TYPE_TIPPING_SENDER',
      'NOTIF_TYPE_CLAIM',
    ],
  },
];

/**
 * Notification categories
 */
export const notificationCategories = {
  reports: notificationReportType,
  replies: ['NOTIF_TYPE_REPLY'],
  tipping: ['NOTIF_TYPE_TIPPING_RECEIVER', 'NOTIF_TYPE_TIPPING_SENDER'],
  tradingMatchOrder: ['NOTIF_TYPE_TRADING_MATCH_ORDER'],
  trending: ['NOTIF_TYPE_NEW_TRENDING'],
  verified: ['NOTIF_TYPE_VERIFIED_BADGE'],
  dayTrade: ['NOTIF_TYPE_SECURITIES_DAY_TRADE'],
};

/**
 * Notification types that includes avatar
 */
// export const notificationTypesWithAvatar = [1, 2, 3, 5, 7, 9, 12, 20, 42];
export const notificationTypesWithAvatar = [
  'NOTIF_TYPE_INBOX',
  'NOTIF_TYPE_MENTIONS',
  'NOTIF_TYPE_FOLLOWING',
  'NOTIF_TYPE_RETWEET',
  'NOTIF_TYPE_LIKE',
  'NOTIF_TYPE_SAVE',
  'NOTIF_TYPE_POSTALERT',
  'NOTIF_TYPE_TARGETPRICE',
  'NOTIF_TYPE_REPLY',
  'NOTIF_TYPE_TRADING_COMMUNITY',
];

/**
 * Normalize notification data (copy-pasted from legacy code)
 * @param {Object} data - raw data
 * @returns {Object}
 */
export const normalizeNotification = (data) => {
  const generated = {
    count: {
      company: 0,
      stream: 0,
      other: 0,
      tipping: 0,
    },
    content: {
      company: [],
      stream: [],
      other: [],
      tipping: [],
    },
  };

  if (!data) {
    return generated;
  }

  if (data.length === 0) {
    return generated;
  }

  const hashmap = {};

  data.forEach((v) => {
    if (!hashmap[v.id]) {
      // check if hashmap data not found
      hashmap[v.id] = true;
      Object.keys(notificationTypesMap).forEach((name) => {
        const index = notificationTypesMap[name].findIndex(
          (mapv) => mapv === v.type,
        );
        if (index > -1) {
          generated.count[name] += 1;
          generated.content[name].push(v);
        }
      });
    }
  });
  return generated;
};

/**
 * Turn normalized data to its origin form
 * @param {Object} data - normalized data
 * @returns {Object}
 */
export const disorganizeNormalizedNotification = (data) => {
  if (!data.company || !data.stream || !data.other) {
    return [];
  }

  let map = [];

  Object.keys(data).forEach((name) => {
    map = map.concat(data[name]);
  });

  return map;
};

/*
 * Parse notification message
 * @Author: stockbit6
 * @Date:   2017-10-17 13:19:47
 * @Last Modified by:   stockbit6
 * @Last Modified time: 2018-01-10 10:02:20
 *
 * TODO:
 *
 * This is copy pasted from legacy code. Only
 * renaming some variables to match with properties
 * passed down from parent component or save myself
 * from linter error.
 *
 * It is unreadable but battle-tested since 2017.
 * I need to consult the author of this snippet and
 * then come back here knowing what this parser is
 * trying to do. No explanation whatsoever.
 *
 * UPDATED:
 * This function is used for format message from masked
 * property response.
 */
export const parseNotificationMessage = (props, selection = '') => {
  let { message, mask, render } = props;
  const { tag } = props;

  // reset mask
  mask = [...mask];

  if (selection) {
    const selected = props[selection];
    if (selected.message) {
      message = selected.message || '';
      mask = selected.mask || {};
    }
  }

  if (!render) render = (currentContent) => currentContent;

  if (!message) message = '';

  if (message.indexOf('\n') > -1) {
    mask.push({
      key: 'brbrbr',
      payload: {
        tag: 'br',
        text: '',
      },
    });
    message = message.replace(/[\r\n]{2,}/g, '\n\n');
    message = message
      .split('\n')
      .map((value) => `${value} [%brbrbr%] `)
      .join('');
  }

  let currentContent = message;

  if (message.indexOf('[%') < 0 || message.indexOf('%]') < 0) {
    if (
      typeof currentContent === 'string' &&
      typeof props.renderParseString === 'function'
    ) {
      return props.renderParseString(currentContent);
    }
    return render(message, tag);
  }

  // eslint-disable-next-line array-callback-return
  mask.map((item) => {
    const search = `[%${item.key}%]`;
    const result = message.indexOf(search) > -1;
    if (result) {
      currentContent = reactStringReplace(currentContent, search, () => {
        let text = '';
        let tagInside = '';
        text = item.payload.text ? item.payload.text : text;
        // Convert PAYLOAD_MASK_TAG_BOLD to b
        tagInside =
          item.payload.tag === 'PAYLOAD_MASK_TAG_BOLD' ? 'b' : item.payload.tag;
        return parseNotificationMessage({
          message: text,
          tag: tagInside,
          mask,
          render,
        });
      });
    }
  });

  if (typeof currentContent.map === 'function') {
    currentContent = currentContent.map((item) => {
      if (
        typeof item === 'string' &&
        typeof props.renderParseString === 'function' &&
        item
      ) {
        return props.renderParseString(item);
      }

      return item;
    });
  }

  if (
    typeof currentContent === 'string' &&
    typeof props.renderParseString === 'function' &&
    currentContent
  ) {
    return props.renderParseString(currentContent);
  }

  return currentContent;
};

/**
 *
 * @param {object} content - Broadcast content. See
 * @returns
 */
export const mergeNotificationCategories = (content) =>
  []
    .concat(content.stream, content.company, content.other, content.tipping)
    // @ts-ignore
    .sort((a, b) => new Date(b.created) - new Date(a.created)); // Sorted by most recent

/**
 * Filter out notification items
 * @param {string} tab - Notification tab name (activity, mention, report, tipping)
 * @returns (content: {}) => {array}
 */
export const filterNotificationItems = (tab) => (content) => {
  const list = [];
  switch (tab) {
    case 'notification':
      return mergeNotificationCategories(content);
    case 'mention':
      return content.stream;
    case 'report':
      return content.company;
    case 'tipping':
      return content.tipping;
    default:
      return list;
  }
};

export const convertParams = (params) => {
  const convertedParams = new URLSearchParams();
  if (params.types && params.types.length > 0) {
    params.types.map((type) => convertedParams.append('types', type));
  }
  if (params.limit) {
    convertedParams.append('limit', params.limit);
  }
  if (params.last_id) {
    convertedParams.append('last_id', params.last_id);
  }
  return convertedParams;
};
