import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// api
// import { AWS_AVATAR_BUCKET } from 'constants/api';
import userApi from 'lib/api/user';
import verifiedBadgeApi from 'lib/api/verifiedBadge';

// Utils
import auth from 'utils/auth';

import { clevertapProfilingUser } from 'utils/clevertap';

// model
import profile from 'lib/models/user';

import { userResponseMapping } from 'utils/userResponseMapping';
import noticeError from 'utils/logger';

// initial state
export const initialState = {
  data: profile.schema,
  isLoading: true,
  error: null,
  message: '',
  // for flagging btn back is clicked
  isClickedPreviousButton: false,
  connectSocialMedia: {
    data: {},
    isLoading: true,
    error: null,
    message: '',
  },
};

// Selectors -------------------------------------------------------------------
export const selectors = createSelector(
  (state) => state.entities.profile,
  (state) => ({
    data: state.data,
    isLoading: state.isLoading,
    error: state.error,
    message: state.message,
    isClickedPreviousButton: state.isClickedPreviousButton,
    connectSocialMedia: state.connectSocialMedia,
  }),
);

export const profileSelector = createSelector(
  (state) => state.entities.profile.data,
  (data) => data,
);

export const proExpirySelector = createSelector(
  (state) => state.entities.profile.data,
  (data) => data.pro_expire_at,
);

export const userIdSelector = createSelector(
  (state) => state.entities.profile.data.id,
  (id) => id,
);

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@redux/user';

const actionType = {
  FETCH_PROFILE: `${CONTEXT}/FETCH_PROFILE`,
  UPDATE_PROFILE: `${CONTEXT}/UPDATE_PROFILE`,
  CONNECT_SOCIAL_MEDIA: `${CONTEXT}/CONNECT_SOCIAL_MEDIA`,
  UPDATE_PREFERENCE: `${CONTEXT}/UPDATE_PREFERENCE`,
};

// effects
export const effects = {
  // getTrendinguser -------------------------------------------------------------------
  getUserProfile: createAsyncThunk(
    actionType.FETCH_PROFILE,
    async (username) => {
      try {
        const response = await userApi.getUserProfile(username);
        if (response.data) {
          const { data, message, error } = response.data;
          const userId = data.id ? Number(data.id) : 0;
          // eslint-disable-next-line no-shadow
          const username = (data.profile && data.profile.username) || '';
          const email = data.email || '';
          const avatar =
            (data.profile &&
              data.profile.avatar &&
              data.profile.avatar.default) ||
            '';
          const responseGetVerifiedBadge =
            await verifiedBadgeApi.getVerifiedBadgeUser(userId);

          if (error) return { message, error };

          //! !! temporarily mapping response from exodus.
          const mappedData = userResponseMapping(data);

          // Clevertap pprofile initialization
          if (responseGetVerifiedBadge && responseGetVerifiedBadge.data) {
            const isEmitenUser =
              (responseGetVerifiedBadge.data &&
                responseGetVerifiedBadge.data.is_emitten) ||
              false;
            // Track if username is empty
            if (!username) {
              noticeError(
                new Error('[CT initialization Error]: username is empty'),
                {
                  place: 'src/lib/entities/profile.js',
                  event: 'getUserProfile',
                  eventParam: { profile: data.profile },
                },
              );
            }

            clevertapProfilingUser({
              isInitProfile: true,
              identity: username,
              email,
              profilePictureUrl: avatar,
              isEmitenUser,
            });
          }

          return { data: mappedData, message };
        }
        throw new Error('Attempt to fetch Trending user Failed');
      } catch (error) {
        return { error };
      }
    },
  ),
  // editUserProfile -------------------------------------------------------------------
  editUserProfile: createAsyncThunk(
    actionType.UPDATE_PROFILE,
    async (requestBody) => {
      try {
        const { preventUpdate, ...body } = requestBody;
        const response = await userApi.editUserProfile(body);
        if (response.data) {
          const { data, message, error } = response.data;
          if (error) {
            return { message, error };
          }
          // sanitize data
          // eslint-disable-next-line no-unused-vars
          const sanitizedData = {
            ...data,
            // avatar: `${AWS_AVATAR_BUCKET}/${data.avatar}`,
          };

          //! !! temporarily mapping response from exodus.
          const mappedData = userResponseMapping(data);

          return { data: mappedData, message, preventUpdate };
        }
        throw new Error('Attempt to fetch Trending user Failed');
      } catch (error) {
        return { error };
      }
    },
  ),
  // userConnectSocialMedia -------------------------------------------------------------------
  userConnectSocialMedia: createAsyncThunk(
    actionType.CONNECT_SOCIAL_MEDIA,
    async ({ socialMediaName, requestBody }, { dispatch }) => {
      try {
        const response = await userApi.userConnectSocialMedia(
          socialMediaName,
          requestBody,
        );

        if (response.data) {
          const { data, message, error } = response.data;

          if (error) {
            return { message, error };
          }

          // fetch API get user profile
          // after connected to facebook
          const userAccess = auth.getUserAccess();
          const { username: usernameAuth } = userAccess;

          dispatch(effects.getUserProfile(usernameAuth));

          return { data, message };
        }
        throw new Error('Attempt to connect social media Failed');
      } catch (error) {
        return { error };
      }
    },
  ),
  updatePreference: createAsyncThunk(
    actionType.UPDATE_PREFERENCE,
    async (requestData) => {
      try {
        const response = await userApi.updatePreference(requestData);

        if (!response.data) {
          throw new Error('Attempt update preference failed');
        }

        const { data, error, error_type, message } = response.data;

        if (error || error_type) {
          throw new Error(message);
        }

        return { data, message };
      } catch (e) {
        return { error: e };
      }
    },
  ),
};

// Reducer ---------------------------------------------------------------------
const reducers = {
  /**
   * updateOnboardingStep
   * Store back onboarding progress step
   * @param step - object of previous progress step
   * @param isClickedPreviousButton - boolean
   */
  updateOnboardingStep: (draft, action) => {
    const { step } = action.payload;

    const newData = {
      ...draft.data,
      onboarding: step,
    };

    draft.data = newData;
  },
  /**
   * resetIsClickedPreviousButton
   * change isClickedPreviousButton to false
   */
  resetIsClickedPreviousButton: (draft) => {
    draft.isClickedPreviousButton = false;
  },
  /**
   * reset profile state
   */
  resetProfileState: (draft) => {
    draft.data = profile.schema;
  },
};

// Extra Reducer ---------------------------------------------------------------
const extraReducers = {
  // Get Userprofile -----------------------------------------------------------
  [effects.getUserProfile.pending]: (state) => {
    state.isLoading = true;
  },

  [effects.getUserProfile.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;
    if (error) {
      state.error = error;
    } else {
      state.data = data;
    }
    state.message = message;
    state.isLoading = false;
  },

  [effects.getUserProfile.rejected]: (state, action) => {
    state.error = action.payload.error;
    state.isLoading = false;
  },
  // Post editUserProfile -----------------------------------------------------------
  // replace state profile
  [effects.editUserProfile.pending]: (state) => {
    state.isLoading = true;
  },

  [effects.editUserProfile.fulfilled]: (state, action) => {
    const { data, message, error, preventUpdate } = action.payload;
    if (error) {
      state.error = error;
    } else {
      // to handle when user has clicked previous button
      // used last onboarding step from state
      // to be used in button next
      const { isClickedPreviousButton } = state;
      const { onboarding: lastProgress } = state.data;

      const newDataWithLastProgress = {
        ...state.data,
        onboarding: lastProgress,
      };

      if (!preventUpdate) {
        state.data = isClickedPreviousButton ? newDataWithLastProgress : data;
      }
    }
    state.message = message;
    state.isLoading = false;
  },

  [effects.editUserProfile.rejected]: (state, action) => {
    state.error = action.payload.error;
    state.isLoading = false;
  },
  // Post userConnectSocialMedia -----------------------------------------------------------
  [effects.userConnectSocialMedia.pending]: (state) => {
    state.connectSocialMedia.isLoading = true;
  },

  [effects.userConnectSocialMedia.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;
    if (error) {
      state.connectSocialMedia.error = error;
    } else {
      state.connectSocialMedia.data = data;
    }
    state.connectSocialMedia.message = message;
    state.connectSocialMedia.isLoading = false;
  },

  [effects.userConnectSocialMedia.rejected]: (state, action) => {
    state.connectSocialMedia.error = action.payload.error;
    state.connectSocialMedia.isLoading = false;
  },
};

// Slice -----------------------------------------------------------------------
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers,
  extraReducers,
});

export default profileSlice;
