import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// Auth Related Modules
import alertAPI from 'lib/api/alert';

// Actions
const CONTEXT = '@side-widget/priceAlert';

const actionType = {
  GET_PRICE_ALERT: `${CONTEXT}/GET_PRICE_ALERT`,
  ADD_PRICE_ALERT: `${CONTEXT}/ADD_PRICE_ALERT`,
  EDIT_PRICE_ALERT: `${CONTEXT}/EDIT_PRICE_ALERT`,
  CANCEL_PRICE_ALERT: `${CONTEXT}/CANCEL_PRICE_ALERT`,
  GET_ALERT_LOG: `${CONTEXT}/GET_ALERT_LOG`,
};

// price alert type
export const PRICE_ALERT_TYPE = {
  PRICE_ALERT: 'priceAlert',
  ALERT_LOG: 'alertLog',
};

// initial state
const initialState = {
  priceAlert: {
    data: [],
    isLoading: true,
    error: null,
    page: 1,
    reachEnd: false,
  },
  addPriceAlert: {
    data: [],
    isLoading: false,
    error: null,
    isSubmit: false,
  },
  editPriceAlert: {
    data: [],
    isLoading: false,
    error: null,
    isSubmit: false,
  },
  cancelPriceAlert: {
    data: [],
    isLoading: false,
    error: null,
    isCancel: false,
  },
  alertLog: {
    data: [],
    isLoading: true,
    error: null,
  },
};

type PriceAlertState = typeof initialState;

// Selectors
export const selectors = createSelector(
  (state) => {
    const {
      priceAlert,
      addPriceAlert,
      editPriceAlert,
      cancelPriceAlert,
      alertLog,
    }: PriceAlertState = state.mainLayout.priceAlert;
    return {
      // all
      priceAlert,
      alertLog,
      addPriceAlert,
      editPriceAlert,
      cancelPriceAlert,
      // price alert
      priceAlertError: priceAlert.error,
      priceAlertData: priceAlert.data,
      priceAlertLoading: priceAlert.isLoading,
      // alert log
      alertLogError: alertLog.error,
      alertLogData: alertLog.data,
      alertLogLoading: alertLog.isLoading,
    };
  },
  (state) => state,
);

export const priceAlertSelector = createSelector(
  (state) => state.mainLayout.priceAlert.priceAlert,
  (state) => state,
);

export const addPriceAlertSelector = createSelector(
  (state) => state.mainLayout.priceAlert.addPriceAlert,
  (state) => state,
);

export const editPriceAlertSelector = createSelector(
  (state) => state.mainLayout.priceAlert.editPriceAlert,
  (state) => state,
);

export const cancelPriceAlertSelector = createSelector(
  (state) => state.mainLayout.priceAlert.cancelPriceAlert,
  (state) => state,
);

export const alertLogSelector = createSelector(
  (state) => state.mainLayout.priceAlert.alertLog,
  (state) => state,
);

// Reducer
export const reducers = {
  setPriceAlert: (state, action: { payload: any }) => {
    state.priceAlert.data = state.priceAlert.data.filter(
      (x) => String(x.alert_id) !== String(action.payload.alert_id),
    );
  },
};

// effects
export const effects = {
  // getPriceAlert
  getPriceAlert: createAsyncThunk(
    actionType.GET_PRICE_ALERT,
    async (options: any = { page: 1, limit: 30 }) => {
      try {
        const { page, limit } = options;
        const opt = {
          page: page || 1,
          limit: limit || 30,
        };
        const response = await alertAPI.getDataPriceAlert(opt);

        if (!response.data) {
          throw new Error('Error when get list price alert');
        }

        const { data, error_type: error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, options, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),
  // getAlertLog
  getAlertLog: createAsyncThunk(actionType.GET_ALERT_LOG, async () => {
    try {
      const opt = {
        page: 1,
        limit: 30,
      };
      const response = await alertAPI.getDataAlertLog(4, opt);

      if (!response.data) {
        throw new Error('Error when get list alert log');
      }

      const { data, error_type: error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),
  // addPriceAlert
  addPriceAlert: createAsyncThunk(
    actionType.ADD_PRICE_ALERT,
    async ({ name, command }: { name: string; command: string }) => {
      try {
        const response = await alertAPI.addPriceAlert(name, command);

        if (!response.data) {
          throw new Error('Error when add price alert');
        }

        const { data, error_type: error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // editPriceAlert
  editPriceAlert: createAsyncThunk(
    actionType.EDIT_PRICE_ALERT,
    async ({ alertId, name, command }: any) => {
      try {
        if (!alertId) {
          throw new Error('Alert ID is required!');
        }
        const response = await alertAPI.editPriceAlert(alertId, name, command);

        if (!response.data) {
          throw new Error('Error when edit price alert');
        }

        const { data, error_type: error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        const updatedAlert = {
          ...data,
          command: JSON.parse(command),
          // Cannot use `data.command` due to stringified JSON from BE is invalid and cannot be parsed.
          // command: JSON.parse(data.command)
        };
        return { updatedAlert, data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // cancelPriceAlert
  cancelPriceAlert: createAsyncThunk(
    actionType.CANCEL_PRICE_ALERT,
    async (id: string) => {
      try {
        const response = await alertAPI.cancelPriceAlert(id);

        if (!response.data) {
          throw new Error('Error when cancel price alert');
        }

        const { data, error_type: error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { id, data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),
};

// Extra / Async Reducer
const extraReducers = (builder) => {
  builder
    .addCase(effects.getPriceAlert.pending, (state) => {
      state.priceAlert.error = null;
      state.priceAlert.isLoading = true;
    })
    .addCase(effects.getPriceAlert.fulfilled, (state, action) => {
      if (action.payload.options.page > 1) {
        state.priceAlert.data = [
          ...state.priceAlert.data,
          ...action.payload.data,
        ];
      } else {
        // To prevent data duplication when sidemenu is reopened / switching tab
        state.priceAlert.data = [...action.payload.data];
      }
      // Update `page` when data is not empty
      if (action.payload.data.length > 0) {
        state.priceAlert.page = action.payload.options.page;
        state.priceAlert.reachEnd = false;
      } else {
        state.priceAlert.reachEnd = true;
      }
      state.priceAlert.error = null;
      state.priceAlert.isLoading = false;
      state.priceAlert.message = action.payload.message;
    })
    .addCase(effects.getPriceAlert.rejected, (state, action) => {
      state.priceAlert.error = action.error;
      state.priceAlert.isLoading = false;
    })
    .addCase(effects.getAlertLog.pending, (state) => {
      state.alertLog.error = null;
      state.alertLog.isLoading = true;
    })
    .addCase(effects.getAlertLog.fulfilled, (state, action) => {
      state.alertLog.data = action.payload.data;
      state.alertLog.error = null;
      state.alertLog.isLoading = false;
      state.alertLog.message = action.payload.message;
    })
    .addCase(effects.getAlertLog.rejected, (state, action) => {
      state.alertLog.error = action.error;
      state.alertLog.isLoading = false;
    })
    .addCase(effects.addPriceAlert.pending, (state) => {
      state.addPriceAlert.error = null;
      state.addPriceAlert.isLoading = true;

      state.addPriceAlert.isSubmit = false;
    })
    .addCase(effects.addPriceAlert.fulfilled, (state, action) => {
      state.addPriceAlert.data = action.payload.data;
      const alert = {
        ...action.payload.data,
        command: JSON.parse(action.payload.data.command),
      };
      state.priceAlert.data = [alert, ...state.priceAlert.data];
      state.addPriceAlert.error = null;
      state.addPriceAlert.isLoading = false;
      state.addPriceAlert.message = action.payload.message;

      state.addPriceAlert.isSubmit = true;
    })
    .addCase(effects.addPriceAlert.rejected, (state, action) => {
      state.addPriceAlert.error = action.error;
      state.addPriceAlert.isLoading = false;

      state.addPriceAlert.isSubmit = false;
    })
    .addCase(effects.editPriceAlert.pending, (state) => {
      state.editPriceAlert.error = null;
      state.editPriceAlert.isLoading = true;

      state.editPriceAlert.isSubmit = false;
    })
    .addCase(effects.editPriceAlert.fulfilled, (state, action) => {
      state.editPriceAlert.data = action.payload.data;
      const { updatedAlert } = action.payload;
      const index = state.priceAlert.data.findIndex(
        (x) => x.alert_id === updatedAlert.alert_id,
      );
      if (index > -1) {
        state.priceAlert.data[index] = updatedAlert;
      }
      state.editPriceAlert.error = null;
      state.editPriceAlert.isLoading = false;
      state.editPriceAlert.message = action.payload.message;

      state.editPriceAlert.isSubmit = true;
    })
    .addCase(effects.editPriceAlert.rejected, (state, action) => {
      state.editPriceAlert.error = action.error;
      state.editPriceAlert.isLoading = false;

      state.editPriceAlert.isSubmit = false;
    })
    .addCase(effects.cancelPriceAlert.pending, (state) => {
      state.cancelPriceAlert.error = null;
      state.cancelPriceAlert.isLoading = true;

      state.cancelPriceAlert.isCancel = false;
    })
    .addCase(effects.cancelPriceAlert.fulfilled, (state, action) => {
      state.cancelPriceAlert.data = action.payload.data;
      const { id } = action.payload;
      state.priceAlert.data = state.priceAlert.data.filter(
        (x) => x.alert_id !== id,
      );
      state.cancelPriceAlert.error = null;
      state.cancelPriceAlert.isLoading = false;
      state.cancelPriceAlert.message = action.payload.message;

      state.cancelPriceAlert.isCancel = true;
    })
    .addCase(effects.cancelPriceAlert.rejected, (state, action) => {
      state.cancelPriceAlert.error = action.error;
      state.cancelPriceAlert.isLoading = false;

      state.cancelPriceAlert.isCancel = false;
    });
};

// Slice
const priceAlertSlice = createSlice({
  name: 'priceAlert',
  initialState,
  reducers,
  extraReducers,
});

export default priceAlertSlice;
