export const TEST_CASE_MESSAGE_COMPONENTS = (comp) => ({
  SHOULD_RENDER_SUCCESSFULLY: `Component ${comp} should render successfully`,
});

// ListRoom
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM =
  'chat-page-left-panel-room-list-index';

// RoomList
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_LIST_ACCEPTED =
  'chat-page-left-panel-room-list-accepted';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_LIST_INVITED =
  'chat-page-left-panel-room-list-invited';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_LIST_ACCEPTED_EMPTY =
  'chat-page-left-panel-room-list-accepted-empty';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_LIST_INVITED_EMPTY =
  'chat-page-left-panel-room-list-invited-empty';
// RoomItem
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_ITEM =
  'chat-page-left-panel-room-item';
// New Message Searched Result
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_NEW_MESSAGE_SEARCHED_RESULT =
  'chat-page-left-panel-new-message-searched-result';

// RoomItemAvatar
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_ROOM_ITEM_AVATAR =
  'chat-page-left-panel-room-item-avatar';
// MessageRequestCount
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_MESSAGE_REQUEST_SECTION =
  'chat-page-left-panel-message-request-section';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_MESSAGE_REQUEST_SECTION_COUNT =
  'chat-page-left-panel-message-request-section-count';
// LastMessagePreview
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_LAST_MESSAGE_PREVIEW =
  'chat-page-left-panel-last-message-preview';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_LAST_MESSAGE_PREVIEW_DELETED =
  'chat-page-left-panel-last-message-preview-deleted';
// Header
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_HEADER =
  'chat-page-left-panel-header';
// HeaderRequest
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_HEADER_REQUEST =
  'chat-page-left-panel-header-request';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_HEADER_REQUEST_CANCEL_BUTTON =
  'chat-page-left-panel-header-request-cancel-button';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_HEADER_REQUEST_EDIT_BUTTON =
  'chat-page-left-panel-header-request-edit-button';
// Footer
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_FOOTER =
  'chat-page-left-panel-footer';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_FOOTER_DELETE =
  'chat-page-left-panel-footer-delete';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_FOOTER_ACCEPT =
  'chat-page-left-panel-footer-accept';
// InputSearch
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_INPUT_SEARCH =
  'chat-page-left-panel-input-search';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_NEW_MESSAGE_BUTTON =
  'chat-page-left-panel-new-message-button';
// ModalConfirmation
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_MODAL_CONFIRMATION =
  'chat-page-left-panel-modal-confirmation';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_MODAL_CONFIRMATION_CANCEL =
  'chat-page-left-panel-modal-confirmation-cancel-button';
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_MODAL_CONFIRMATION_CONFIRM =
  'chat-page-left-panel-modal-confirmation-confirm-button';
// Tooltip create group
export const DATA_CY_NAME_CHAT_PAGE_LEFT_PANEL_TOOLTIP_CREATE_GROUP =
  'chat-page-left-panel-tooltip-create-group';

export const QUERY_STRING_TYPE_INVTED = 'request';