import moment from 'moment';

const formatDate = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

export const userResponseMapping = (data) => {
  const {
    id,
    created_at,
    statistics,
    profile,
    privilege,
    exchange,
    ir_symbols,
    badges,
    is_alert_enabled,
    is_blocked,
    is_followed,
    email,
    last_modified_at,
    last_login_at,
    is_activated,
    has_password,
    is_newsletter_subscriber,
    is_phone_verified,
    is_shown_in_discovery,
    preferences,
    onboarding,
    free_trial,
    sns,
    trading,
    support,
    pro,
    ban,
    onesignal_hash,
  } = data;

  const {
    follower_count,
    following_count,
    reputation_count,
    idea_count,
    like_count,
    view_count,
  } = statistics;

  const {
    username,
    fullname,
    about,
    website,
    avatar,
    country,
    language,
    gender,
    location,
    address,
    birthday,
    phone,
    occupation,
  } = profile;

  const result = {
    id: String(id),
    username: username,
    fullname: fullname,
    email: email,
    activated: is_activated ? '1' : '0',
    banned: String(ban.code),
    user_banned_until: formatDate(ban.until),
    ban_reason: ban.reason === '' ? null : ban.reason,
    last_login: formatDate(last_login_at),
    created: formatDate(created_at),
    modified: formatDate(last_modified_at),
    avatar: avatar.default,
    about: about,
    location: location,
    website: website,
    occupation: occupation,
    gender: gender,
    birthday: birthday,
    phone: phone.formatted,
    address: address,
    hide_email: preferences.privacy.is_hide_email ? '1' : '0',
    // user_attr
    user_priv: String(privilege.code),
    // tutorial
    // user_newsletter
    country: country,
    exchange: exchange,
    facebook_id: sns.facebook_id,
    apple_id: sns.apple_id,
    google_id: sns.google_id,
    email_subscribe: is_newsletter_subscriber ? '1' : '0',
    user_phone_verification: is_phone_verified ? '1' : '0',
    facebook_hide: preferences.privacy.is_hide_facebook ? '1' : '0',
    language: language,
    user_preferences: {
      stream_version: preferences.stream.version,
    },
    user_trading: String(trading.account_id),
    // user_badge_top
    additional: {
      reputations: reputation_count,
      followers: String(follower_count),
      ideas: String(idea_count),
      following: String(following_count),
      likes: like_count,
      views: view_count,
    },
    followed: is_followed ? 1 : 0,
    ispro:
      trading.is_pro || pro.is_pro || !moment().isAfter(free_trial.expired_at)
        ? 1
        : 0,
    pro_active_since: formatDate(pro.active_since),
    pro_expire_at: formatDate(pro.expired_at),
    tradingpro: trading.is_pro ? 1 : 0,
    // official
    trending: badges.is_trending ? 1 : 0,
    ir_symbol: ir_symbols,
    isblocked: is_blocked ? 1 : 0,
    alert: is_alert_enabled ? 1 : 0,
    onboarding: onboarding.current_step,
    onboarding_done: onboarding.is_done ? 1 : 0,
    discover: is_shown_in_discovery ? 1 : 0,
    ftcode: free_trial.code,
    ftend: formatDate(free_trial.expired_at),
    realtrading_access: trading.has_realtrading_access ? 1 : 0,
    top_badge: badges.is_top ? 1 : 0,
    // is_term_agreed
    intercom: {
      ...support.intercom,
      email: support.intercom.email,
      created_at: support.intercom.created_at,
      phone: support.intercom.phone,
    },
    password: has_password ? 1 : 0,
    onesignal_hash: onesignal_hash,
    support: {
      id: support.id,
    },
  };

  return result;
};
