/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import {
  createSelector,
  createSlice,
  createAction,
  createAsyncThunk,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';

// APIs
import wsApi from 'lib/api/ws';
import auth from 'utils/auth';

// Utils
import wsUtils from 'utils/webSocket';
import router from 'next/router';
import { batch } from 'react-redux';
import { showAlertNotification } from 'global/Notification';
import alertSlice, {
  effects as alertEffects,
} from 'global/MainLayout/SideWidget/PriceAlert/slice';
import { OldIncomingNotificationN2 } from '../../../@types/old-socket';

const PRICE_ALERT_KEY = 'price-alert';

const setPriceAlertToLocalStorage = (alert) => {
  try {
    window?.localStorage?.setItem(PRICE_ALERT_KEY, JSON.stringify(alert));
    return true;
  } catch {
    return false;
  }
};

interface iState {
  wskey?: string | number | null;
  status: string;
  auth: number;
  listening: boolean;
  livedata_channels: {
    stream: any[];
    company: any[];
    orderbook: any[];
    hotlist: boolean;
  };
  // socket data may be wrong, dependent on how it will be used
  // feel free to adjust it
  socket_data: {
    stream?: any[];
    notification: any[];
    alert: any;
  };
}

// Initial state
const initialState: iState = {
  wskey: null,
  status: '3',
  auth: 0,
  listening: false,
  livedata_channels: {
    stream: [],
    company: [],
    orderbook: [],
    hotlist: false,
  },
  // socket data may be wrong, dependent on how it will be used
  // feel free to adjust it
  socket_data: {
    stream: null,
    notification: [],
    alert: null,
  },
};

type WebsocketState = typeof initialState;
const wsSelector = (state) => state.websocket;

// selectors
export const selectors = createSelector(wsSelector, (ws: WebsocketState) => ({
  ...ws,
}));

export const wsKeySelector = createSelector(
  (state) => state.websocket.wskey,
  (state) => state,
);

export const notifWsSelector = createSelector(
  (state) => state.websocket.socket_data.notification,
  (state) => state,
);

export const alertWsSelector = createSelector(
  (state) => state.websocket.socket_data.alert,
  (state) => state,
);

export const channelsSelector = createSelector(
  (state) => state.websocket.livedata_channels,
  (state) => state,
);

export const websocketListeningSelector = createSelector(
  (state) => state.websocket.listening,
  (state) => state,
);

export const wsStatusSelector = createSelector(
  (state) => state.websocket.status,
  (state) => state,
);

export const streamWsSelector = createSelector(
  (state) => state.websocket.socket_data.stream,
  (state) => state,
);

// Actions
const CONTEXT = '@redux/websocket';

export const actionType = {
  INIT_WEBSOCKET: `${CONTEXT}/INIT_WEBSOCKET`,
  INIT_LISTEN_DATA: `${CONTEXT}/INIT_LISTEN_DATA`,
  INIT_CONFIG: createAction(`${CONTEXT}/INIT_CONFIG`),
  UPDATE_ACTIVE_STATUS: createAction(`${CONTEXT}/UPDATE_ACTIVE_STATUS`),
  UPDATE_CHANNELS_CONFIG: createAction<{ [key: string]: string[] }>(
    `${CONTEXT}/UPDATE_CHANNELS_CONFIG`,
  ),
  ADD_SOCKET_DATA: createAction(`${CONTEXT}/ADD_SOCKET_DATA`),
};

export const actions = actionType;

// API actions
const getWskey = async () => {
  try {
    const response = await wsApi.getWSKey();

    if (!response.data) {
      throw new Error('Attempt to get wskey failed');
    }

    const { error, message, data } = response.data;

    if (error) {
      // @ts-ignore
      throw new Error({ error, message });
    }

    const wsKey = wsUtils.convertWSKeyArray(data.key);

    wsUtils.setLocalWskey(wsKey);
    return wsKey;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// effects
let attempt = 0;
export const effects = {
  initWebsocket: createAsyncThunk(
    actionType.INIT_WEBSOCKET,
    /**
     * Initiate websocket
     * @param {Object} param
     * @param {Object} param.primus - primus
     * @param {boolean} param.isUserLogin - flag if user is logged in
     */
    // @ts-ignore
    async (primus = null, { dispatch, getState }) => {
      const {
        // @ts-ignore
        entities: {
          credentials: { isLoggedIn: isUserLogin },
        },
        // @ts-ignore
        websocket: { status },
      } = getState();

      const parsedCookie = auth.getToken();
      const {
        accessToken: { token },
      } = parsedCookie;

      if (primus === null || !isUserLogin || !token) {
        throw new Error('No websocket active');
      }

      if (token) {
        // @ts-ignore
        primus.on('open', () => {
          console.log('Initiate websocket connection');

          const cookie = auth.getToken();
          const {
            accessToken: { token: accessToken },
          } = cookie;

          if (accessToken) {
            getWskey().then((key) => {
              if (Array.isArray(key)) {
                const [useWsKey] = key;
                // @ts-ignore
                dispatch(actionType.INIT_CONFIG({ primus, wskey: useWsKey }));

                console.log('Connection socket is alive and kicking');
                // on connection open, get websocket key
                // every time we refresh the page we will re-fetch ws key
                // thus we always get wskey from api
              }
            });
          }

          return true;
        });

        // @ts-ignore
        primus.on('end', (e) => {
          console.warn(
            'Web Socket is dying, re-open another connection after 3 second',
          );
          console.log('Web Socket is dying');
          const onlineStatus = window.navigator.onLine ? '2' : '1';
          // update status
          if (status !== onlineStatus) {
            // @ts-ignore
            dispatch(actionType.UPDATE_ACTIVE_STATUS(onlineStatus));
          }

          return false;
        });

        // @ts-ignore
        primus.on('reconnect', () => {
          console.info(`Web Socket trying to reconnect ${attempt++}`);
          console.log('Web Socket reconnecting');
          const onlineStatus = window.navigator.onLine ? '2' : '1';
          if (status !== onlineStatus) {
            // @ts-ignore
            dispatch(actionType.UPDATE_ACTIVE_STATUS(onlineStatus));
          }

          // Close primus connection if attempt reconnection >= 3 times
          if (attempt >= 3) {
            // @ts-ignore
            primus.end();
          }
        });

        // @ts-ignore
        primus.on('reconnected', () => {
          console.info('Web Socket reconnected!');
        });

        // @ts-ignore
        primus.on('reconnect failed', () => {
          console.info('Web Socket failed to reconnect');
          console.log('Web Socket reconnect failed');
          const onlineStatus = window.navigator.onLine ? '2' : '1';
          if (status !== onlineStatus) {
            // @ts-ignore
            dispatch(actionType.UPDATE_ACTIVE_STATUS(onlineStatus));
          }
        });

        // @ts-ignore
        primus.on('error', (e) => {
          console.error(
            "Web Socket unexpectedly error , here's the message ",
            e,
          );
          console.log('Web Socket unexpectedly error');
          const onlineStatus = window.navigator.onLine ? '2' : '1';
          if (status !== onlineStatus) {
            // @ts-ignore
            dispatch(actionType.UPDATE_ACTIVE_STATUS(onlineStatus));
          }
        });
      }

      return true;
    },
  ),
  initListenData: createAsyncThunk(
    actionType.INIT_LISTEN_DATA,
    // @ts-ignore
    async (primus = null, { dispatch, getState }) => {
      // @ts-ignore
      if (!primus) {
        return false;
      }

      const {
        // @ts-ignore
        websocket: { status: statusWebsocket },
      } = getState();

      // prepare max batch operation count
      const currentPages = router.asPath;
      const batchPages = ['catalog', 'sector', 'stream'];

      const isBatch = batchPages.some((item) => currentPages.includes(item));

      // save list of action that need to run batch
      // to prevent too many rerenderer ui
      let batchOperation: ActionCreatorWithoutPayload[] = [];
      let INTERVAL_DURATION = 700;

      if (currentPages.includes('watchlist')) {
        INTERVAL_DURATION = 200;
      }

      if (isBatch) {
        setInterval(() => {
          if (batchOperation.length > 0) {
            batch(() => {
              batchOperation.map((action) => dispatch(action));
              batchOperation = [];
            });
          }
        }, INTERVAL_DURATION);
      }

      // @ts-ignore
      primus.on('data', async (data) => {
        if (statusWebsocket !== '3' && data.__i) {
          const authtext = data.__i.toLowerCase();
          if (authtext === 'auth_ok') {
            console.log('==== AUTH_OK! ====');
            // @ts-ignore
            dispatch(actionType.UPDATE_ACTIVE_STATUS('3'));
          }
        }

        if (statusWebsocket === '3' && data.__e) {
          if (data.__e.includes('Unauthorized Connection')) {
            const onlineStatus = window.navigator.onLine ? '2' : '1';
            // @ts-ignore
            if (statusWebsocket !== onlineStatus) {
              // @ts-ignore
              dispatch(actionType.UPDATE_ACTIVE_STATUS(onlineStatus));
            }
          }
        }

        if (data.S?.length > 0) {
          dispatch(
            // @ts-ignore
            actionType.ADD_SOCKET_DATA({
              stream: data.S[0],
            }),
          );
        }

        if (data.A) {
          const alert = data.A;

          dispatch(
            // @ts-ignore
            actionType.ADD_SOCKET_DATA({
              alert,
            }),
          );

          if (alert && alert.msg) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            showAlertNotification(alert, () => {});
            dispatch(alertSlice.actions.setPriceAlert(alert));
            dispatch(alertEffects.getAlertLog());
            setPriceAlertToLocalStorage(alert);
          }
        }

        if (data.N2) {
          dispatch(
            // @ts-ignore
            actionType.ADD_SOCKET_DATA({
              notification: data.N2,
            }),
          );
        }
      });

      return true;
    },
  ),
};

// reducers
const reducers = {
  clearAlert: (state) => {
    state.socket_data.alert = null;
  },
  clearNotification: (state, action) => {
    const newNotification = state.socket_data.notification.filter(
      (notif) => notif.id !== action.payload,
    );
    state.socket_data.notification = newNotification;
  },
  resetNotification: (state) => {
    state.socket_data.notification = [];
  },
  setAlert: (state, action) => {
    state.socket_data.alert = action.payload;
  },
};

// Extra reducers
const extraReducers = (builder) => {
  builder
    .addCase(actionType.INIT_CONFIG, (state, action) => {
      const { primus, wskey } = action.payload;
      const currentState = { ...state };

      state.wskey = wskey;
      state.status = '3';

      // initiate config to ws
      wsUtils.initConfig(primus, currentState, wskey);
    })
    .addCase(actionType.INIT_LISTEN_DATA, (state) => {
      state.listening = true;
    })
    .addCase(actionType.UPDATE_ACTIVE_STATUS, (state, action) => {
      const status = action.payload;

      if (state.status !== status) {
        state.status = status;
      }
    })
    // update current live channels config
    .addCase(actionType.UPDATE_CHANNELS_CONFIG, (state, action) => {
      const newChannelsConfig = action.payload;
      const currentState = { ...state };

      const {
        livedata_channels: { orderbook, company },
      } = currentState;

      let newState = {
        ...state.livedata_channels,
        ...newChannelsConfig,
      };

      // TODO: Improve state update from multiple components
      if (Array.isArray(newChannelsConfig?.company)) {
        const newCompanies = Array.from(
          new Set([...company, ...newChannelsConfig?.company]),
        );
        newState = {
          ...newState,
          company: newCompanies,
        };
      }

      if (Array.isArray(newChannelsConfig?.orderbook)) {
        const newOrderbook = Array.from(
          new Set([...orderbook, ...newChannelsConfig?.orderbook]),
        );

        newState = {
          ...newState,
          orderbook: newOrderbook,
        };
      }

      const shouldUpdateConfig = wsUtils.updateGeneralWsConfig(
        currentState,
        newState,
      );

      const updatedChannel = {
        ...state.livedata_channels,
        ...(shouldUpdateConfig || {}),
      };
      if (shouldUpdateConfig) {
        state.livedata_channels = updatedChannel;
      }

      // update and write websocket
      wsUtils.updateConfig(window?.primus, updatedChannel, currentState.wskey);
    })
    .addCase(actionType.ADD_SOCKET_DATA, (state, action) => {
      const { notification, ...socketData } = action.payload;

      if (notification) {
        if (
          !state.socket_data.notification.find(
            (notif: OldIncomingNotificationN2) => notif.id === notification.id,
          )
        ) {
          state.socket_data.notification = [
            ...state.socket_data.notification,
            notification,
          ];
        }
      }

      state.socket_data = {
        ...state.socket_data,
        ...socketData,
      };
    });
};

const webSocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers,
  extraReducers,
});

export default webSocketSlice;
