import { parseEmoji } from 'utils/stream';
import stringReplace from 'react-string-replace-recursively';
import React from 'react';

const parseNotificationMessage = (text, maskedHtml) => {
  const parseHtmlMask = (mask) => {
    if (Array.isArray(maskedHtml)) {
      const parsed = mask.reduce((obj, curr) => {
        // eslint-disable-next-line no-param-reassign
        obj[curr.mask] = curr;

        return obj;
      }, {});

      return parsed;
    }

    return mask;
  };

  let parsedText = parseEmoji(text);
  const parsedHtml = parseHtmlMask(maskedHtml);

  const config = {
    breaks: {
      pattern: /(\n|\r\n)/g,
      matcherFn: () => React.createElement('br'),
    },
    symbols: {
      pattern: /(\[%[a-z0-9_]*%\])/gi,
      textFn: (text) => {
        const cleanedSymbol = text.match(/\w+/g);
        return cleanedSymbol;
      },
      matcherFn: (rawText) => {
        const htmlData = parsedHtml[rawText];
        const { attr = {}, text, tag } = htmlData;
        return React.createElement(tag, {}, text);
      },
    },
  };

  parsedText = stringReplace(config)(parsedText);
  return parsedText;
};

export { parseNotificationMessage };
