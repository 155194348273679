/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */

import {
  IMAGE_TYPE_COMMON,
  IMAGE_TYPE_GIF,
} from 'global/StreamCompose/constants';

/**
 * Function to parse post content to data which can be read in
 * component `StreamCompose`
 * @param {Object} post - Post object
 * @return {Object} parsed post
 */
const parseEmoji = (textContent) => textContent;

const parseStreamPostContent = (post) => {
  const {
    content_original: content,
    images,
    images_data: imagesData,
    attachment,
    target_price: targetPrice,
    commenter_type: commenterType,
    postid: postId,
    parent_postid: parentPostId,
  } = post;

  const parsedContent = {
    content: parseEmoji(content),
    images,
    images_data: imagesData,
    documents: attachment || [],
    targetPrice: null,
    commenter_type: commenterType,
    postId,
    parentPostId,
  };

  if (targetPrice?.length > 0) {
    parsedContent.targetPrice = {
      target_duration: targetPrice[0].duration,
      target_price: targetPrice[0].target_price,
      target_symbol: targetPrice[0].symbol,
    };
  }

  // Polling is not included to parsed content since polling is not editable

  return parsedContent;
};

/**
 * Get last Youtube url from user content
 * @param {string} contentOriginal - original content from stream post
 */
function getYoutubeURL(contentOriginal = '') {
  const exp =
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/gm;

  const list = contentOriginal.match(exp);

  if (list && list.length >= 1) {
    return list[list.length - 1];
  }

  return null;
}

/**
 * parse youtube video URL
 * @param {string} youtubeURL - URL of youtube video
 */
function parseYoutubeURL(youtubeURL = '') {
  const exp =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]{1,50}\?v=|embed\/|v\/|e\/|live\/|shorts\/)?)([\w\-]{11})(\S+)?$/gm;

  const list = [...youtubeURL.matchAll(exp)].map((m) => ({
    url: m[0],
    protocol: m[1],
    www: m[2],
    host: m[3],
    slug: m[4],
    id: m[5],
    params: m[6],
  }));

  return list.length === 1 ? list[0] : null;
}

/**
 * Get youtube video ID from youtube URL
 * @param {string} youtubeURL - URL of youtube video
 */
function getYoutubeVideoId(youtubeURL = '') {
  return youtubeURL ? parseYoutubeURL(youtubeURL)?.id : null;
}

/**
 * Count words from escaped string
 * @param {string} str
 * @returns {number} words length
 */
const countWords = (str) => {
  let newStr = str.trim();
  newStr = newStr.replace(/[ ]{2,}/gi, ' ');
  newStr = newStr.replace(/\n /, '\n');

  return newStr.split(/\s+/).length;
};

const MAX_WORDS = 10;
const MAX_NEWLINE = 2;

/**
 * Decide to show read more flag
 * @param {string} contentOriginal - Original content
 * @param {number=} maxWords - Maximum words
 * @param {number=} maxNewLine - Max paragraph
 * @returns {boolean}
 */
const isReadMoreShow = (
  contentOriginal = '',
  maxWords = MAX_WORDS,
  maxNewLine = MAX_NEWLINE,
) =>
  countWords(contentOriginal) > maxWords ||
  contentOriginal.split(/\r\n|\r|\n/).length > maxNewLine;

/**
 * Parse Etension from Url
 * @param {string} unparsedUrl - url file string
 * @returns {string} - file extension from the url
 */
const parseExtension = (unparsedUrl) => {
  // Remove everything to the last slash in URL
  let url = unparsedUrl.substr(1 + unparsedUrl.lastIndexOf('/'));

  // Break URL at ? and take first part (file name, extension)
  url = url.split('?')[0];

  // Sometimes URL doesn't have ? but #, so we should aslo do the same for #
  url = url.split('#')[0];

  // Now we have only extension
  url = url.split('.').pop();
  return url;
};

/**
 * Parse Images Data Json to parsed Format
 * @param {Array} images - images_data JSON response from api
 * @returns {Array}
 */
const parseExistImage = (images) => {
  const parsedImages = images.map((image) => ({
    dimensions: {
      width: image.width,
      height: image.height,
    },
    imagePreviewUrl: image.image,
    file: image.image,
    isNew: false,
    type:
      parseExtension(image.image).toLowerCase() === 'gif'
        ? IMAGE_TYPE_GIF
        : IMAGE_TYPE_COMMON,
  }));
  return parsedImages;
};

/**
 * Convert stream V3 response to stream V2 response
 * @param {Object} stream - stream V3 response
 * @returns {Object} stream V2 response
 */
function transformStreamV3(stream = {}) {
  if (!stream) {
    return stream;
  }

  const UserRole = {
    PRIVILEGE_MEMBER: 0,
    PRIVILEGE_ADMIN: 1,
    PRIVILEGE_MODERATOR: 2,
    PRIVILEGE_CONFIGURATOR: 3,
    PRIVILEGE_SUPER_ADMIN: 4,
  };

  const CountryID = {
    COUNTRY_ID: 'ID',
    COUNTRY_MY: 'MY',
    COUNTRY_SG: 'SG',
  };

  const transformBoolean = (status) => (status ? 1 : 0);

  const transformed = {
    ...stream,
    ...stream.status,
    ...stream.user,
    country: CountryID[stream.user.country],
    created: stream.created_at,
    follow: transformBoolean(stream.status.is_followed),
    is_author: transformBoolean(stream.user.is_author),
    is_unavailable: stream.status.is_unavailable,
    isnews: transformBoolean(stream.title_url),
    ispinned: transformBoolean(stream.status.is_pinned),
    ispro: transformBoolean(stream.user.is_pro),
    liked: transformBoolean(stream.status.is_liked),
    likes: stream.total_likes,
    newsfeed_img: stream.news_feed.img,
    newsfeed_label: stream.news_feed.label,
    newsfeed_source: stream.news_feed.source,
    official: transformBoolean(stream.user.is_verified),
    parent_postid: stream.parent_stream_id,
    postid: stream.stream_id,
    replies: stream.total_replies.toString(),
    replying_to: transformBoolean(stream.reply_to),
    replyto: stream.reply_to.toString(),
    reposted: transformBoolean(stream.status.is_reposted),
    repostedfrom: transformStreamV3(stream.reposted_from),
    saved: transformBoolean(stream.status.is_saved),
    titleurl: stream.title_url,
    trending: transformBoolean(stream.status.is_trending),
    updated: stream.updated_at,
    user_priv: UserRole[stream.user.user_privilege],
    userid: stream.user.user_id,
    isJunk: Boolean(stream.status?.is_junk),
    isSpam: Boolean(stream.status?.is_spam),
  };

  if (transformed.last_reply && transformed.last_reply.user) {
    transformed.last_reply = {
      ...transformed.last_reply,
      ...transformed.last_reply.user,
      postid: transformed.last_reply.stream_id,
    };

    delete transformed.last_reply.user;
  }

  if (transformed.reply_content) {
    transformed.reply_content = {
      ...transformed.reply_content,
      titleurl: transformed.reply_content.title_url,
    };

    delete transformed.reply_content.title_url;
  }

  delete transformed.user;
  delete transformed.status;
  return transformed;
}

/**
 * Convert stream V3 response to stream V2 response
 * @param {Array} streamList - stream V3 response
 * @returns {Array} stream V2 response
 */
function transformV3Response(streamList = []) {
  if (!streamList) {
    return streamList;
  }

  return streamList.map(transformStreamV3);
}

function transformConversationV3(conversation = {}) {
  if (!conversation) {
    return conversation;
  }

  const payload = {
    ...conversation,
    parent: transformStreamV3(conversation.parent),
    replies: transformV3Response(conversation.replies),
  };

  return payload;
}

/**
 * Remove empty value from object
 * @param {Object} obj
 * @returns {Object}
 * @example
 * const obj = {
 *  a: '',
 * b: null,
 * c: undefined,
 * d: 'd',
 * }
 * cleanupStreamPayload(obj) // { d: 'd' }
 */
function cleanupStreamPayload(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== '' && value !== null && value !== undefined,
    ),
  );
}

export {
  parseEmoji,
  parseStreamPostContent,
  getYoutubeVideoId,
  getYoutubeURL,
  countWords,
  isReadMoreShow,
  parseExistImage,
  transformStreamV3,
  transformV3Response,
  transformConversationV3,
  cleanupStreamPayload,
};
